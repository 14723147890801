import React from 'react';
import { graphql } from 'gatsby';

import Website from 'components/ui-setup';
import Layout from 'components/layout';
import Industries from 'components/industries';

type Props = {
  data: Object,
};

const IndustriesPage = ({ data }: Props) => {
  const {
    industriesJson,
    getInTouchJson,
    logoJson,
    socialMediaJson,
    siteJson,
    cookieConsentJson,
  } = data;

  return (
    <Website
      content={{
        ...industriesJson,
        getInTouch: getInTouchJson,
        cookieConsent: cookieConsentJson,
        menu: siteJson?.content,
        logo: logoJson,
        socialMedia: socialMediaJson,
      }}>
      <Layout>
        <Industries />
      </Layout>
    </Website>
  );
};

export default IndustriesPage;

export const query = graphql`
  query IndustriesPageQuery {
    industriesJson {
      hero {
        background {
          image {
            xl
            lg
            md
            sm
            xs
          }
        }
        title
      }
      cases {
        cards {
          id
          title
          content
          list {
            title
            items
          }
          image
        }
      }
      meta {
        description
        title
      }
    }
    getInTouchJson {
      background {
        image {
          xs
          sm
          md
          lg
          xl
        }
      }
      title
      text
      buttonLink
      buttonLabel
    }
    siteJson {
      content {
        whoWeAre {
          id
          title
          type
          position
          visible
          menuItems {
            ourLeadership {
              id
              link
              title
              type
              visible
            }
            vision {
              id
              link
              title
              type
              visible
            }
          }
        }
        whatWedo {
          id
          title
          type
          position
          visible
          menuItems {
            industries {
              id
              link
              title
              type
              visible
            }
            models {
              id
              link
              title
              type
              visible
            }
            practices {
              id
              link
              title
              type
              visible
            }
          }
        }
        techProfessionals {
          id
          link
          position
          title
          type
          visible
        }
        careers {
          id
          link
          position
          title
          type
          visible
        }
        blog {
          id
          link
          position
          title
          type
          visible
        }
        contact {
          id
          link
          position
          title
          type
          visible
        }
      }
    }
    cookieConsentJson {
      message
      buttons {
        accept
        reject
      }
    }
    logoJson {
      colorful
      white
    }
    socialMediaJson {
      label
      media {
        id
        image
        url
      }
    }
  }
`;
